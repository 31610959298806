<template>
  <div>
    <div class="table_sty">
      <div class="froms" style="margin: 5px">
        <p class="describe">考核动态</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="fromData" class="from">
            <el-form-item label="部门：" label-width="60px" style="width: 310px;">
              <el-input disabled @click.native="drawer_show = true" placeholder="请点击选择部门" size="mini" class="form-inp"
                v-model="department_name"></el-input>
            </el-form-item>
            <el-form-item label="统计周期：" label-width="90px">
              <div>
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="fromData.control_begin" type="datetime"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="fromData.control_end" type="datetime"
                  placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_list()" type="primary" icon="el-icon-search">查询</el-button>
              <!-- <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              > -->
            </div>
          </div>
        </div>
      </div>
      <div class="tree-form-div" style="margin-top: 20px;">
        <div class="tree-form">
          <el-table max-height="500" :data="list_Data" row-key="id" border default-expand-all
            :tree-props="{ children: 'items', hasChildren: 'hasChildren' }">
            <el-table-column prop="name" label="部门名称"> </el-table-column>

            <el-table-column prop="total" label="考核总分"></el-table-column>
            <el-table-column v-for="(item, index) in scorepropData" :key="index" :label="item.name"
              :min-width="item.width ? item.width : ''">
              <template slot-scope="scope">
                <el-link type="primary" @click="on_deductList(item, scope.row)">{{ scope.row[item.prop] }}</el-link>
              </template>
            </el-table-column>

            <el-table-column label="当前分值">
              <template slot-scope="scope">
                {{ scope.row.total + scope.row.sum_minus }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-drawer title="选择部门" :visible.sync="drawer_show" direction="ltr">
        <BepartmentTree @on_controlid="on_controlid" />
      </el-drawer>
      <el-dialog center title="详情" :visible.sync="pop_particulars" @closed="way_closed">
        <el-table :data="gridData">
          <div v-if="prop_type == 'other_minus'" :key="Math.random()">
            <el-table-column prop="score" :key="1" label="分值"></el-table-column>
            <el-table-column :key="1" prop="department_name" label="部门"></el-table-column>
            <el-table-column :key="1" prop="handle_time" label="发生日期"></el-table-column>
            <el-table-column :key="1" prop="desc" label="原因"></el-table-column>
          </div>

          <div v-else-if="prop_type == 'spection_minus'">
            <el-table-column prop="score" label="考核分值"></el-table-column>

            <el-table-column prop="name" label="督察内容"></el-table-column>
            <el-table-column prop="form_detail" label="督察意见"></el-table-column>
            <el-table-column prop="nickname" label="督察人"></el-table-column>
            <el-table-column prop="add_time" label="督察时间"></el-table-column>
          </div>

          <div v-else :key="Math.random()">
            <el-table-column prop="score" label="考核分值"></el-table-column>
            <el-table-column prop="source_names" :label="`${name_dots}名称`"></el-table-column>
            <el-table-column prop="from_nickname" label="发布人"></el-table-column>
            <el-table-column prop="add_time" label="下发日期"></el-table-column>
            <el-table-column prop="expire_time" label="任务截止日期"></el-table-column>
          </div>
        </el-table>

        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { departmentdynamic, way_flowcode_url } from "../../assets/request/api";
import BepartmentTree from "../../components/Bepartment_tree";

export default {
  name: "organizational_structure",
  components: {
    BepartmentTree,
  },
  data() {
    return {
      drawer_show: false,
      title: "",
      dialog_show: false,
      list_Data: [],
      fromData: {
        control_begin: "",
        control_end: "",
        department_id: "",
      },
      department_name: "",
      controlDate: [],

      scorepropData: [
        {
          prop: "danger_minus",
          name: "风险点扣分",
          name_dot: "风险点",
          url: "/api/dangerous/minusScore",
          width: "60",
        },
        {
          prop: "personnel_minus",
          name: "重点人群扣分",
          name_dot: "重点人群",
          url: "/api/personnel/minusScore",
          width: "60",
        },
        {
          prop: "activity_minus",
          name: "活动扣分",
          name_dot: "活动",
          url: "/api/activity/minusScore",
          width: "50",
        },
        {
          prop: "eduction_minus",
          name: "安全宣教扣分",
          name_dot: "安全宣教",
          url: "/api/eduction/minusScore",
          width: "60",
        },
        {
          prop: "regulation_minus",
          name: "制度管理扣分",
          name_dot: "制度管理",
          url: "/api/regulation/minusScore",
          width: "60",
        },
        {
          prop: "cases_minus",
          name: "事件管理扣分",
          name_dot: "事件管理",
          url: "/api/cases/minusScore",
          width: "60",
        },
        {
          prop: "hdanger_minus",
          name: "隐患管理扣分",
          name_dot: "隐患管理",
          url: "/api/hdanger/minusScore",
          width: "60",
        },
        {
          prop: "inspection_minus",
          name: "专项检查扣分",
          name_dot: "专项检查",
          url: "/api/inspection/minusScore",
          width: "60",
        },
        {
          prop: "spection_minus",
          name: "随机督察扣分",
          url: "/api/spection/minusScore",
          width: "60",
        },
        {
          prop: "eval_minus",
          name: "安全月评扣分",
          name_dot: "安全月评",
          url: "/api/evaluation/minusScore",
          width: "60",
        },
        {
          prop: "other_minus",
          name_dot: "",
          name: "其他",
          url: "/api/score/index",
          width: "50",
        },
      ],

      //统计周期结束时间的最低选择时间
      pickerOptions: {},
      //详情
      pop_particulars: false,
      //当前请求的url
      present_URL: "",
      //部门id
      departmentid: "",
      //点击prop
      prop_type: "",
      name_dots: "",
      page_size: 10,
      page: 1,
      total: 0,
      gridData: [],
    };
  },
  created() {
    this.way_list();
  },
  methods: {
    on_controlid(item) {
      console.log(item);
      this.department_name = item.name;
      this.fromData.department_id = item.id;
      this.way_list();
    },
    way_list() {
      let { fromData } = this;
      departmentdynamic(fromData).then((res) => {
        let { data, control_time } = res.data;
        this.list_Data = data;
        fromData.control_begin = control_time.control_begin;
        fromData.control_end = control_time.control_end;
        this.DateChange(1);
        this.controlDate = [
          control_time.control_begin,
          control_time.control_end,
        ];
      });
    },
    //统计周期结束日期禁用

    DateChange(type) {
      if (!type) {
        this.fromData.control_end = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.fromData.control_begin
          );
        },
      };
    },
    //管控选择时间
    on_controlDate() {
      let { controlDate, fromData } = this;
      if (controlDate == null) {
        fromData.control_begin = "";
        fromData.control_end = "";
      } else {
        fromData.control_begin = controlDate[0];
        fromData.control_end = controlDate[1];
      }
    },
    //查看扣分详情
    on_deductList(item, row) {
      let { url, prop, name_dot } = item;
      this.present_URL = url;
      this.name_dots = name_dot;
      this.prop_type = prop;
      this.departmentid = row.id;
      this.way_requestList();
      this.pop_particulars = true;
    },
    way_requestList() {
      let { fromData, present_URL, departmentid, page } = this;
      let { control_begin, control_end } = fromData;
      let From_Data = {
        control_begin,
        control_end,
        department_id: departmentid,
        page,
      };
      way_flowcode_url(present_URL, From_Data).then((res) => {
        console.log(res);
        let { total, data } = res.data;
        this.total = total;
        this.gridData = data;
      });
    },
    way_page(val) {
      this.page = val;
      this.way_requestList();
    },
    way_closed() {
      this.page = 1;
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.tree-form-div {
  width: 100%;
}

.tree-form {
  // height: 400px;
  // overflow-y: scroll;
  margin: 5px;
  background: #ffffff;
  padding: 20px;
}

.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

.operation {
  .isty {
    padding-right: 15px;
  }
}

.custom-tree-node {
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid #ebecec;
}

.el-tree-node {
  margin: 3px 0;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  margin-left: 30px;
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.form-inp {
  width: 200px;
}

.from {
  display: flex;
  flex-wrap: wrap;
}

.paging {
  text-align: center;
  padding: 10px;
}
</style>
